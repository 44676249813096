import axios from 'axios';
import firebase from './firebase';

// const BASE_URL = 'http://0.0.0.0:8080';
const BASE_URL = 'https://api.mindfulread.app';

const getAuthHeader = async (token = "") => {
  if (token == "") {
    token = await firebase.auth().currentUser.getIdToken();
  }

  return {
    headers: {
      Authorization: `Bearer ${token}`,
    },
  };
};

export const fetchUserData = async () => {
  const authHeader = await getAuthHeader();
  const response = await axios.get(`${BASE_URL}/me`, authHeader);
  return response.data;
};

export const fetchUserSettings = async () => {
  const authHeader = await getAuthHeader();
  const response = await axios.get(`${BASE_URL}/settings`, authHeader);
  return response.data;
};

export const updateUserSettings = async (settings) => {
  const authHeader = await getAuthHeader();
  await axios.post(`${BASE_URL}/settings`, settings, authHeader);
};

export const updateUserName = async (name) => {
  const authHeader = await getAuthHeader();
  await axios.post(`${BASE_URL}/me`, { name }, authHeader);
};

export const exchangeToken = async (token) => {
  const authHeader = await getAuthHeader(token);
  const response = await axios.get(`${BASE_URL}/exchange_token`, authHeader);
  return response.data;
};

export const listArticles = async (limit, last_id) => {
  const authHeader = await getAuthHeader();
  const response = await axios.get(`${BASE_URL}/articles`, {
    params: { limit, last_id },
    ...authHeader,
  });
  return response.data;
};

export const session = async () => {
  const authHeader = await getAuthHeader();
  const response = await axios.get(`${BASE_URL}/session`, authHeader);
  return response.data;
};