import React, { useEffect, useState } from 'react';
import InfiniteScroll from 'react-infinite-scroll-component';
import { listArticles } from './Api';

const ArticleList = () => {
  const [articles, setArticles] = useState([]);
  const [hasMore, setHasMore] = useState(true);

  const fetchMoreData = async () => {
    const lastId = articles.length > 0 ? articles[articles.length - 1].sorting_id : -1;
    const newArticles = await listArticles(10, lastId);

    if (newArticles.length === 0) {
      setHasMore(false);
    } else {
      setArticles(articles.concat(newArticles));
    }
  };

  useEffect(() => {
    fetchMoreData();
  }, []);

  const getStatusTextAndStyle = (status) => {
    switch (status) {
      case 2:
        return { text: '', style: '' };
      case 1:
      case 0:
        return { text: 'Not processed yet', style: '' };
      default:
        return { text: 'Error processing article', style: 'text-red-500', 'tooltip': 'Something went wrong' };
    }
  };

  const trimSummary = (summary) => {
    if (summary.length <= 400) {
      return summary;
    } else {
      return summary.slice(0, 400) + '...';
    }
  };

  return (
    <div>
      <InfiniteScroll
        dataLength={articles.length}
        next={fetchMoreData}
        hasMore={hasMore}
        loader={<p>Loading...</p>}
      >
        <ul className="space-y-4">
          {articles.map((article) => (
          <li key={article.id} className="border p-4 rounded-lg shadow-md">
            <a href={article.url} target="_blank" rel="noopener noreferrer">
              <img
                src={article.image}
                alt="Thumbnail"
                className="w-24 h-24 object-cover float-left mr-4"
              />
            </a>
            <div>
              <h2 className="text-xl font-semibold">
                <a href={article.url} target="_blank" rel="noopener noreferrer">
                  {article.title}
                </a>
              </h2>
              <p className={`text-gray-600 mt-2 ${getStatusTextAndStyle(article.status).style}`}  title={getStatusTextAndStyle(article.status).tooltip}>
                {getStatusTextAndStyle(article.status).text}
              </p>
              <p className="text-gray-600">{trimSummary(article.summary)}</p>
              <p className="text-gray-500 mt-2">
                {new Date(article.created_at).toLocaleDateString()}
              </p>
            </div>
          </li>
        ))}
        </ul>
      </InfiniteScroll>
    </div>
  );
};

export default ArticleList;